<template>
  <div class="login">
    <div class="box m-t-2 m-b-2" v-loading="isLoadingAccessToken" :element-loading-text="loadingText">
      <div v-if="isBrowserIE === null || isBrowserEdge">
        <h2 class="login__headline">
          <span>M-iPlan</span>
        </h2>

        <el-alert
          v-if="hasIdToken || hasAccessToken"
          :title="$t('SUCCESS')"
          type="success"
          :description="`Bitte warten. M-iPlan wird für Sie vorbereitet.`"
          show-icon
          :closable="false"
        ></el-alert>

        <el-alert
          v-if="hasErrorDescription"
          :title="$t('ERROR')"
          type="error"
          :description="hasErrorDescription"
          show-icon
          :closable="false"
        >
        </el-alert>

        <!-- Display login/register button -->
        <el-button-group class="flex flex-wrap w-100 m-t-1" v-if="!hasIdToken && !hasAccessToken">
          <a class="el-button el-button--primary w-100--md" :href="loginUrl">
            {{ $t('LOGIN') }}
          </a>
          <a class="el-button el-button--text w-100--md" :href="registrationUrl">
            {{ $t('REGISTER') }}
          </a>
        </el-button-group>
      </div>

      <!-- Only show this message on old browsers -->
      <MessageOldBrowser v-if="isBrowserIE !== null && !isBrowserEdge" />
    </div>
  </div>
</template>

<script>
/* eslint-disable no-undef */
import { get } from 'lodash';
import { authorize } from '@/services/auth';
import { isBrowserEdge, isBrowserIE } from '@/services/browser';
import { MODULE_NAME as AUTH_MODULE } from '@/store/modules/auth';
import MessageOldBrowser from '@/components/MessageOldBrowser';
import jwt_decode from 'jwt-decode';

export default {
  name: 'Login',

  components: {
    MessageOldBrowser
  },

  computed: {
    accessToken() {
      return this.queryParams.get('access_token');
    },

    hasIdToken() {
      let result = false;

      if (this.idToken && this.idTokenExpiresIn && this.tokenType) {
        result = true;
      }

      return result;
    },

    hasAccessToken() {
      let result = false;
      if (this.accessToken && this.tokenType) {
        result = true;
      }
      return result;
    },

    hasError() {
      return this.queryParams.get('error');
    },

    hasErrorDescription() {
      return this.queryParams.get('error_description');
    },

    idToken() {
      return this.queryParams.get('id_token');
    },

    idTokenExpiresIn() {
      let result = this.queryParams.get('id_token_expires_in');
      if (result) {
        result = parseInt(result);
      }
      return result;
    },

    isBrowserEdge() {
      return isBrowserEdge();
    },

    isBrowserIE() {
      return isBrowserIE();
    },

    loginUrl() {
      const host = get(window, 'app.login.host');
      const path = get(window, 'app.login.signIn');
      return `${host}${path}`;
    },

    registrationUrl() {
      const host = get(window, 'app.login.host');
      const path = get(window, 'app.login.registration');
      return `${host}${path}`;
    },

    queryParams() {
      return new URLSearchParams(window.location.search);
    },

    tokenType() {
      return this.queryParams.get('token_type');
    }
  },

  data() {
    return {
      isLoadingAccessToken: false,
      loadingText: ''
    };
  },

  mounted() {
    console.log('Login mounted', this.isBrowserIE, isBrowserIE(), isBrowserEdge());
    this.loadingText = this.$store.state.app.loadingText;

    if (this.$route.query.logout) {
      this.showLogoutMessage();
    }

    if (this.hasIdToken) {
      this.getAccessTokenFromIdToken(this.idToken);
    } else if (this.hasAccessToken) {
      this.handleLoginWithAccessToken(this.accessToken);
    }
  },

  methods: {
    async getAccessTokenFromIdToken(idToken) {
      try {
        this.isLoadingAccessToken = true;
        const { data } = await authorize(idToken);
        if (data) {
          this.handleSuccessLogin(data);
        }
      } catch (error) {
        console.log('getAccessTokenFromIdToken', { error });
      } finally {
        this.isLoadingAccessToken = false;
      }
    },

    handleLoginWithAccessToken(accessToken, tokenType) {
      const { name } = jwt_decode(accessToken);
      const MESSAGE = `Welcome ${name}`;

      // Set authorization header
      this.$http.defaults.headers.common['Authorization'] = `${tokenType} ${accessToken}`;

      // Store token
      this.$session.set('token', accessToken);
      this.$store.commit(`${AUTH_MODULE}/SET_AUTH`, { token: accessToken });

      // Remove Sessionnumber to get a new one
      this.$session.remove('Sessionnumber');

      // Welcome message
      this.$message({
        message: MESSAGE,
        type: 'success',
        showClose: true,
        duration: 10000
      });

      // Send event to analytics
      if (window.ga) {
        ga('send', 'event', 'Authentication', 'signin', name);
      }

      // Remove the query params in the url
      const origin = window.location.origin;
      const path = '#/pages';
      window.history.pushState({}, '', `${origin}/${path}`);

      // Redirect to main page
      this.$router.push({ path: '/pages', query: {} });
    },

    handleSuccessLogin(data) {
      const accessToken = get(data, 'access_token');
      const tokenType = get(data, 'token_type');
      const privacyAccepted = get(data, 'privacy_accepted', 'false');

      if (!privacyAccepted) {
        this.$store.commit('dialog/OPEN_DIALOG', {
          loadComponent: 'FormPrivacyPolicy',
          title: this.$t('PRIVACY_POLICY'),
          width: '50%'
        });
      }

      this.handleLoginWithAccessToken(accessToken, tokenType);
    },

    showLogoutMessage() {
      this.$message({
        message: this.$t('LOGOUT_SUCCESSFULL'),
        type: 'success',
        showClose: true,
        duration: 5000
      });
    }
  }
};
</script>

<style lang="scss">
.login {
  min-height: 90vh;
  height: auto;
  padding: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (min-width: $screen-lg) {
    min-height: 70vh;
  }

  &__headline {
    font-family: $font-brand;
    font-size: 1.6rem;
    font-weight: 400;
    margin-top: 0;
    margin-bottom: 1rem;
  }

  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    /* IE10+ specific styles go here */
    .box {
      padding: 1rem !important;
    }
  }

  .el-button-group {
    .el-button {
      width: 100%;

      @media screen and (min-width: $screen-md) {
        width: 50%;
      }
    }
  }
}
</style>
