<template>
  <div class="notification--old-browser">
    <el-alert
      :title="$t('BROWSER_NOT_SUPPORTED')"
      :description="$t('PLEASE_USE_MODERN_BROWSER_TO_SURF_PAGE')"
      type="error"
      show-icon
      :closable="false"
    ></el-alert>
    <p class="m-t-2 m-b-0">{{ $t('BELOW_YOU_CAN_FIND_LIST_WITH_MODERN_BROWSER') }}</p>
    <ul class="list">
      <li class v-for="item in items" :key="item.id">
        <img :src="item.icon" :title="item.name" />
        <a :href="item.link" class="el-button el-button--text" :title="item.name" target="_blank">{{ $t('MORE_INFOS') }}</a>
      </li>
    </ul>
  </div>
</template>

<script>
import SVGChrome from '@/assets/img/chrome.png';
import SVGFirefox from '@/assets/img/firefox.png';
import SVGSafari from '@/assets/img/safari.png';
import SVGEdge from '@/assets/img/edge.png';

export default {
  name: 'MessageOldBrowser',

  data() {
    return {
      items: [
        {
          id: 1,
          name: 'Google Chrome',
          icon: SVGChrome,
          link: 'https://www.google.com/chrome'
        },
        {
          id: 2,
          name: 'Mozilla Firefox',
          icon: SVGFirefox,
          link: 'https://www.firefox.com/'
        },
        {
          id: 3,
          name: 'Safari',
          icon: SVGSafari,
          link: 'https://www.apple.com/safari/'
        },
        {
          id: 4,
          name: 'Microsoft Edge',
          icon: SVGEdge,
          link: 'https://www.microsoft.com/en-us/windows/microsoft-edge'
        }
      ]
    };
  }
};
</script>

<style lang="scss">
.notification--old-browser {
  .list {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin: 1rem 0 0 0;
    padding-left: 0;
    justify-content: space-around;
  }

  li {
    text-align: center;

    a {
      display: block;
      text-align: center;
    }
  }
}
</style>
